import { Box, Button, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { Bin } from "models";
import React, { useRef, useState } from "react";
import ScrollMenu from "react-horizontal-scroll-menu";
import { useHistory } from "react-router";
import { ArrowForward, ArrowBack } from "@material-ui/icons";
import { useMobile } from "hooks";
import BinCardV2 from "./BinCardV2";

interface Props {
  bins: Bin[];
  duplicateBin: (bin: Bin) => void;
  title?: string;
  gridView?: boolean;
  loadMore?: (newTranslation: number) => void;
  startingTranslate: number;
  valDisplay?: "high" | "low" | "average";
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    gridListTile: {
      position: "relative",
      minHeight: "184px",
      height: "auto !important",
      //width: "184px",
      padding: 2
    },
    hidden: {
      visibility: "hidden"
    }
  })
);

export default function BinsList(props: Props) {
  const { bins, duplicateBin, title, gridView, loadMore, startingTranslate, valDisplay } = props;
  const classes = useStyles();
  const history = useHistory();
  const isMobile = useMobile();
  const [duplicate, setDuplicate] = useState(false);
  const scrollRef = useRef<any>(null);

  const goToBin = (i: number) => {
    let path = "/bins/" + bins[i].key();
    history.push(path, {
      bin: bins[i]
    });
  };

  const scroll = () => {
    return (
      <ScrollMenu
        wheel={false}
        ref={scrollRef}
        alignCenter={false}
        inertiaScrolling
        translate={startingTranslate}
        hideArrows
        hideSingleArrow
        arrowDisabledClass={classes.hidden}
        onUpdate={params => {
          if (scrollRef.current.state) {
            if (scrollRef.current.getOffsetAtEnd() === params.translate && loadMore) {
              loadMore(params.translate);
            }
          }
        }}
        onSelect={e => {
          if (!duplicate) {
            goToBin(e as number);
          }
        }}
        arrowLeft={
          <Button style={{ height: 184, display: isMobile ? "none" : "block" }}>
            <ArrowBack />
          </Button>
        }
        arrowRight={
          <Button style={{ height: 184, display: isMobile ? "none" : "block" }}>
            <ArrowForward />
          </Button>
        }
        data={bins.map((b, i) => (
          <Box key={i} className={classes.gridListTile}>
            <BinCardV2
              bin={b}
              duplicateBin={duplicateBin}
              dupHovered={setDuplicate}
              valDisplay={valDisplay}
            />
          </Box>
        ))}
      />
    );
  };

  const grid = () => {
    return (
      <Grid container direction="row">
        {bins.map((b, i) =>
          isMobile ? (
            <Grid
              item
              xs={6}
              sm={4}
              md={3}
              lg={2}
              key={i}
              className={classes.gridListTile}
              onClick={() => goToBin(i)}>
              <BinCardV2
                bin={b}
                duplicateBin={duplicateBin}
                dupHovered={setDuplicate}
                valDisplay={valDisplay}
              />
            </Grid>
          ) : (
            <Box
              key={i}
              style={{ width: "184px" }}
              className={classes.gridListTile}
              onClick={() => goToBin(i)}>
              <BinCardV2
                bin={b}
                duplicateBin={duplicateBin}
                dupHovered={setDuplicate}
                valDisplay={valDisplay}
              />
            </Box>
          )
        )}
      </Grid>
    );
  };

  return (
    <React.Fragment>
      {title && <Typography>{title}</Typography>}
      {gridView ? grid() : scroll()}
    </React.Fragment>
  );
}
