import {
  SignalCellular4Bar,
  SignalCellularOff,
  SignalWifi4Bar,
  SignalWifiOff
} from "@material-ui/icons";
import moment from "moment";
import { pond } from "protobuf-ts/pond";
import React from "react";
import { getTextSecondary } from "theme";

export interface ConnectivityDescriber {
  icon: any;
  description: string;
  tooltip: string;
}

export enum Activity {
  unknown = 0,
  active = 1,
  away = 2,
  missing = 3
}

function getActivity(lastActive: string, checkInPeriod: number, now: moment.Moment): Activity {
  let secondsSinceLastActive = moment.duration(now.diff(moment(lastActive))).asSeconds();
  if (secondsSinceLastActive > checkInPeriod * 3) {
    return Activity.missing;
  } else if (secondsSinceLastActive > checkInPeriod) {
    return Activity.away;
  } else if (isNaN(secondsSinceLastActive)) {
    return Activity.unknown;
  }
  return Activity.active;
}

function getColour(activity: Activity): string {
  switch (activity) {
    case Activity.active:
      return "var(--status-ok)";
    case Activity.away:
      return "var(--status-risk)";
    case Activity.missing:
      return getTextSecondary();
    default:
      return "var(--status-unknown)";
  }
}

function getIcon(platform: pond.DevicePlatform, activity: Activity): JSX.Element {
  let colour = getColour(activity);
  if (
    platform === pond.DevicePlatform.DEVICE_PLATFORM_ELECTRON ||
    platform === pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR
  ) {
    if (activity === Activity.missing) {
      return <SignalCellularOff style={{ color: colour }} />;
    }
    return <SignalCellular4Bar style={{ color: colour }} />;
  }
  if (activity === Activity.missing) {
    return <SignalWifiOff style={{ color: colour }} />;
  }
  return <SignalWifi4Bar style={{ color: colour }} />;
}

function getTooltip(platform: pond.DevicePlatform): string {
  switch (platform) {
    case pond.DevicePlatform.DEVICE_PLATFORM_ELECTRON ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_BLACK ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_BLUE ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_GREEN:
      return "Communicates via Cellular";
    case pond.DevicePlatform.DEVICE_PLATFORM_PHOTON ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI_BLUE ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI ||
      pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI_S3:
      return "Communicates via Wi-Fi";
    default:
      return "";
  }
}

function getDescription(lastActive: string): string {
  if (lastActive === "") {
    return "Never active";
  }
  return "Active " + moment(lastActive).fromNow();
}

export function describeConnectivity(
  platform: pond.DevicePlatform,
  lastActive: string,
  checkInPeriod: number,
  now: moment.Moment
): ConnectivityDescriber {
  let activity = getActivity(lastActive, checkInPeriod, now);
  return {
    icon: getIcon(platform, activity),
    description: getDescription(lastActive),
    tooltip: getTooltip(platform)
  };
}
