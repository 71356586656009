import { AxiosResponse } from "axios";
import { useHTTP } from "hooks";
import { permissionToString } from "pbHelpers/Permission";
import { pond } from "protobuf-ts/pond";
import { useGlobalState } from "providers/StateContainer";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pondURL } from "./pond";

export interface IMineAPIContext {
  addMine: (mine: pond.MineSettings) => Promise<AxiosResponse<pond.AddMineResponse>>;
  getMine: (key: string) => Promise<AxiosResponse<pond.GetMineResponse>>;
  addDevice: (key: string, deviceID: string, permissions: pond.Permission[]) => Promise<any>;
  addComponent: (
    key: string,
    deviceID: string,
    componentKey: string,
    permissions: pond.Permission[]
  ) => Promise<any>;
  listMines: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListMinesResponse>>;
  listMinesSimple: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean
  ) => Promise<AxiosResponse<pond.ListMinesSimpleResponse>>;
  removeMine: (key: string) => Promise<AxiosResponse<pond.RemoveMineResponse>>;
  updateMine: (
    mine: pond.MineSettings,
    componentPreferences: Map<string, pond.MineComponentPreferences>
  ) => Promise<AxiosResponse<pond.UpdateMineResponse>>;
}

export const MineAPIContext = createContext<IMineAPIContext>({} as IMineAPIContext);

interface Props {}

export default function MineProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addMine = (mine: pond.MineSettings) => {
    return post<pond.AddMineResponse>(pondURL("/mines" + (as ? "?as=" + as : "")), mine);
  };

  const getMine = (key: string) => {
    return get<pond.GetMineResponse>(pondURL("/mines/" + key + (as ? "?as=" + as : "")));
  };

  const listMines = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean,
    keys?: string,
    types?: string
  ) => {
    const url = pondURL(
      "/mines" +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        ("&order=" + (order ? order : "asc")) +
        ("&by=" + (orderBy ? orderBy : "deviceId")) +
        (search ? "&search=" + search : "") +
        (asRoot ? "&asRoot=" + asRoot.toString() : "") +
        (as ? "&as=" + as : "") +
        (keys ? "&keys=" + keys : "") +
        (types ? "&types=" + types : "")
    );
    return get<pond.ListMinesResponse>(url);
  };

  const listMinesSimple = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string,
    asRoot?: boolean
  ) => {
    const url = pondURL(
      "/minesSimple" +
        "?limit=" +
        limit +
        "&offset=" +
        offset +
        ("&order=" + (order ? order : "asc")) +
        ("&by=" + (orderBy ? orderBy : "deviceId")) +
        (search ? "&search=" + search : "") +
        (asRoot ? "&asRoot=" + asRoot.toString() : "") +
        (as ? "&as=" + as : "")
    );
    return get<pond.ListMinesSimpleResponse>(url);
  };

  const removeMine = (key: string) => {
    return del<pond.RemoveMineResponse>(pondURL("/mines/" + key + (as ? "?as=" + as : "")));
  };

  const updateMine = (
    mine: pond.MineSettings,
    componentPreferences: Map<string, pond.MineComponentPreferences>
  ) => {
    let request = pond.UpdateMineRequest.create();
    let container = pond.MineComponentPreferencesContainer.create();
    request.settings = mine;
    //if (componentPreferences) {
    componentPreferences.forEach((pref, key) => {
      container.preferences.push(pref);
      container.keys.push(key);
    });
    request.componentPreferences = container;
    //}
    return put<pond.AddMineResponse>(pondURL("/mines"), request);
  };

  const addDevice = (key: string, deviceID: string, permissions: pond.Permission[]) => {
    if (as)
      return post(pondURL(`/mines/` + key + `/addDevice/` + deviceID + `?as=${as}`), {
        permissions: permissions.map(permission => permissionToString(permission))
      });
    return post(pondURL(`/mines/` + key + `/addDevice/` + deviceID), {
      permissions: permissions.map(permission => permissionToString(permission))
    });
  };

  const addComponent = (
    key: string,
    deviceID: string,
    componentKey: string,
    permissions: pond.Permission[]
  ) => {
    if (as)
      return post(
        pondURL(`/mines/` + key + `/addComponent/` + deviceID + "/" + componentKey + `?as=${as}`),
        {
          permissions: permissions.map(permission => permissionToString(permission))
        }
      );
    return post(pondURL(`/mines/` + key + `/addComponent/` + deviceID + "/" + componentKey), {
      permissions: permissions.map(permission => permissionToString(permission))
    });
  };

  return (
    <MineAPIContext.Provider
      value={{
        addMine,
        getMine,
        addDevice,
        addComponent,
        listMines,
        listMinesSimple,
        removeMine,
        updateMine
      }}>
      {children}
    </MineAPIContext.Provider>
  );
}

export const useMineAPI = () => useContext(MineAPIContext);
