import HeaterDarkIcon from "assets/components/heaterDark.png";
import HeaterLightIcon from "assets/components/heaterLight.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  height?: number;
  width?: number;
}

export default function ObjectHeaterIcon(props: Props) {
  const themeType = useThemeType();
  const { type, height, width } = props;

  const src = () => {
    if (type) {
      return type === "light" ? HeaterLightIcon : HeaterDarkIcon;
    }

    return themeType === "light" ? HeaterDarkIcon : HeaterLightIcon;
  };

  if (height || width) {
    return <img alt="tempIcon" src={src()} height={height} width={width} />;
  }

  return <ImgIcon alt="group" src={src()} />;
}
