import {
  createStyles,
  IconButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
  Theme,
  Tooltip
} from "@material-ui/core";
import { blue } from "@material-ui/core/colors";
import RemoveSelfIcon from "@material-ui/icons/ExitToApp";
import MoreIcon from "@material-ui/icons/MoreVert";
import GroupSettingsIcon from "@material-ui/icons/Settings";
import ShareObjectIcon from "@material-ui/icons/Share";
//import SensorIcon from "@material-ui/icons/Sensor";
import SensorIcon from "@material-ui/icons/SettingsInputAntenna";
import ObjectUsersIcon from "@material-ui/icons/AccountCircle";
import ObjectTeamsIcon from "@material-ui/icons/SupervisedUserCircle";
import BinSettings from "bin/BinSettings";
import { Bin, binScope, Component, User } from "models";
import { pond } from "protobuf-ts/pond";
import React, { useState } from "react";
import ObjectUsers from "user/ObjectUsers";
import RemoveSelfFromObject from "user/RemoveSelfFromObject";
import ShareObject from "user/ShareObject";
import { isOffline } from "utils/environment";
import ObjectTeams from "teams/ObjectTeams";
import BinDuplication from "./BinDuplication";
import BinsIcon from "products/Bindapt/BinsIcon";
import HelpIcon from "@material-ui/icons/Help";
import BinSensors from "./BinSensors";
import { useGlobalState, useSnackbar, useUserAPI } from "providers";
import { useMobile } from "hooks";

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    shareIcon: {
      color: blue["500"],
      "&:hover": {
        color: blue["600"]
      }
    },
    removeIcon: {
      color: "var(--status-alert)"
    },
    red: {
      color: "var(--status-alert)"
    }
  });
});

interface Props {
  bin: Bin;
  permissions: pond.Permission[];
  refreshCallback: () => void;
  userID: string;
  components?: Map<string, Component>;
  setComponents?: React.Dispatch<React.SetStateAction<Map<string, Component>>>;
  updateBinStatus?: (componentKeys: string[], removed?: boolean) => void;
}

interface OpenState {
  share: boolean;
  users: boolean;
  teams: boolean;
  settings: boolean;
  sensors: boolean;
  removeSelf: boolean;
  duplication: boolean;
}

export default function BinActions(props: Props) {
  const classes = useStyles();
  const {
    bin,
    permissions,
    refreshCallback,
    userID,
    components,
    setComponents,
    updateBinStatus
  } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [{ user }, dispatch] = useGlobalState();
  const isMobile = useMobile();
  const { error } = useSnackbar();
  const userAPI = useUserAPI();
  const [openState, setOpenState] = useState<OpenState>({
    share: false,
    users: false,
    teams: false,
    settings: false,
    sensors: false,
    removeSelf: false,
    duplication: false
  });

  const startTour = () => {
    if (user) {
      let u = user.protobuf();
      if (u.status) {
        u.status.finishedBinIntro = "";
        userAPI
          .updateUser(userID, u)
          .then(() => {
            dispatch({ key: "user", value: User.any(u) });
          })
          .catch((err: any) => error(err));
      }
    }
  };

  const groupMenu = () => {
    const canShare = permissions.includes(pond.Permission.PERMISSION_SHARE);
    const canManageUsers = permissions.includes(pond.Permission.PERMISSION_USERS);
    return (
      <Menu
        id="groupMenu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        keepMounted
        disableAutoFocusItem>
        {!isOffline() && canShare && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, share: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ShareObjectIcon className={classes.shareIcon} />
            </ListItemIcon>
            <ListItemText primary="Share" />
          </MenuItem>
        )}
        {!isOffline() && canManageUsers && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, users: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ObjectUsersIcon />
            </ListItemIcon>
            <ListItemText primary="Users" />
          </MenuItem>
        )}
        {!isOffline() && canManageUsers && (
          <MenuItem
            dense
            onClick={() => {
              setOpenState({ ...openState, teams: true });
              setAnchorEl(null);
            }}
            button>
            <ListItemIcon>
              <ObjectTeamsIcon />
            </ListItemIcon>
            <ListItemText primary="Teams" />
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            setOpenState({ ...openState, duplication: true });
            setAnchorEl(null);
          }}
          button>
          <ListItemIcon>
            <BinsIcon />
          </ListItemIcon>
          <ListItemText primary="Duplicate Bin" />
        </MenuItem>
        {isMobile && (
          <MenuItem
            dense
            onClick={() => {
              setAnchorEl(null);
              startTour();
            }}
            button>
            <ListItemIcon>
              <HelpIcon />
            </ListItemIcon>
            <ListItemText primary="Redo Help Tour" />
          </MenuItem>
        )}
        {isMobile && (
          <MenuItem
            dense
            onClick={() => {
              setAnchorEl(null);
              setOpenState({ ...openState, sensors: true });
            }}
            button>
            <ListItemIcon>
              <SensorIcon />
            </ListItemIcon>
            <ListItemText primary="Bin Sensors" />
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            setOpenState({ ...openState, removeSelf: true });
            setAnchorEl(null);
          }}
          button>
          <ListItemIcon>
            <RemoveSelfIcon className={classes.red} />
          </ListItemIcon>
          <ListItemText primary="Leave" />
        </MenuItem>
      </Menu>
    );
  };

  const dialogs = () => {
    const hasWritePermission = permissions.includes(pond.Permission.PERMISSION_WRITE);
    const key = bin.key();
    const label = bin.name();
    return (
      <React.Fragment>
        <BinSettings
          mode="update"
          bin={bin}
          canEdit={hasWritePermission}
          open={openState.settings}
          userID={userID}
          onClose={refresh => {
            if (refresh === true) {
              refreshCallback();
            }
            setOpenState({ ...openState, settings: false });
          }}
        />
        <BinSensors
          mode="update"
          bin={bin}
          canEdit={hasWritePermission}
          open={openState.sensors}
          userID={userID}
          components={components}
          setComponents={setComponents}
          updateBinStatus={updateBinStatus}
          onClose={refresh => {
            if (refresh === true) {
              refreshCallback();
            }
            setOpenState({ ...openState, sensors: false });
          }}
        />
        <ShareObject
          scope={binScope(key)}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.share}
          closeDialogCallback={() => setOpenState({ ...openState, share: false })}
        />
        <ObjectUsers
          scope={binScope(key)}
          label={label}
          permissions={permissions}
          isDialogOpen={openState.users}
          closeDialogCallback={() => setOpenState({ ...openState, users: false })}
          refreshCallback={refreshCallback}
        />
        <BinDuplication
          open={openState.duplication}
          closeDialog={() => {
            setOpenState({ ...openState, duplication: false });
          }}
          bin={bin}
          refreshCallback={refreshCallback}
        />
        <RemoveSelfFromObject
          scope={binScope(key)}
          label={label}
          isDialogOpen={openState.removeSelf}
          closeDialogCallback={() => setOpenState({ ...openState, removeSelf: false })}
        />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      {!isMobile && (
        <Tooltip title="Redo help tour">
          <IconButton onClick={startTour}>
            <HelpIcon />
          </IconButton>
        </Tooltip>
      )}
      {!isMobile && (
        <Tooltip title="Bin Sensors">
          <IconButton
            id="tour-bin-sensors"
            onClick={() => setOpenState({ ...openState, sensors: true })}>
            <SensorIcon />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title="Bin Settings">
        <IconButton
          id="tour-bin-settings"
          onClick={() => setOpenState({ ...openState, settings: true })}>
          <GroupSettingsIcon />
        </IconButton>
      </Tooltip>
      <IconButton
        aria-owns={anchorEl ? "groupMenu" : undefined}
        aria-haspopup="true"
        id="tour-bin-kebab"
        onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}>
        <MoreIcon />
      </IconButton>
      {groupMenu()}
      {dialogs()}
      <ObjectTeams
        scope={binScope(bin.key())}
        label="Teams"
        permissions={permissions}
        isDialogOpen={openState.teams}
        refreshCallback={refreshCallback}
        closeDialogCallback={() => setOpenState({ ...openState, teams: false })}
      />
    </React.Fragment>
  );
}
