import { Avatar, Card, Grid, Switch, Tab, Tabs, Tooltip, Typography } from "@material-ui/core";
import { Group } from "@material-ui/icons";
import MaterialTable from "material-table";
import { Site, User } from "models";
import { pond } from "protobuf-ts/pond";
import { useGlobalState, useSiteAPI } from "providers";
import React, { useCallback, useEffect, useState } from "react";
import PageContainer from "./PageContainer";
import { getTableIcons } from "common/ResponsiveTable";
import { useHistory } from "react-router";
import { useMobile } from "hooks";
import JobsiteIcon from "products/Construction/JobSiteIcon";

interface SiteWithData {
  site: Site;
  users: User[];
  heaterData: pond.ObjectHeaterData[];
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

export default function Sites() {
  const siteAPI = useSiteAPI();
  const [loading, setLoading] = useState(false);
  const [{ as }] = useGlobalState();
  const history = useHistory();
  const [sites, setSites] = useState<SiteWithData[]>([]);
  const isMobile = useMobile();
  const [currentTab, setCurrentTab] = useState(0);

  const loadSites = useCallback(() => {
    if (loading) return;
    setLoading(true);
    //make a site api that gets an array of objects that contain the site, its devices, and teams
    siteAPI
      .listSitesPageData(100, 0, "asc", "siteName", undefined, as)
      .then(resp => {
        buildTableData(resp.data.sites);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [as, siteAPI]); //eslint-disable-line react-hooks/exhaustive-deps

  const buildTableData = (siteData: pond.SiteData[]) => {
    let data: SiteWithData[] = [];
    siteData.forEach(site => {
      if (site.site?.settings?.siteName) {
        let newTableData: SiteWithData = {
          site: Site.any(site.site),
          users: [],
          heaterData: []
        };
        if (site.users) {
          newTableData.users = site.users.map(u => User.any(u));
        }
        if (site.heaterData) {
          newTableData.heaterData = site.heaterData;
        }
        data.push(newTableData);
      }
    });
    setSites([...data]);
  };

  useEffect(() => {
    loadSites();
  }, [loadSites]);

  const siteInfoDisplay = (site: SiteWithData) => {
    return (
      <Grid container direction="column">
        <Grid item>
          <Typography style={{ fontWeight: 650 }}>{site.site.siteName()}</Typography>
        </Grid>
        <Grid item>
          {site.heaterData.length + (site.heaterData.length > 1 ? " Heaters" : " Heater")}
        </Grid>
      </Grid>
    );
  };

  const goToSite = (jobsite: SiteWithData) => {
    let path = "/jobsites/" + jobsite.site.settings.key;
    history.push(path, {});
  };

  const updateActive = (active: boolean, site: Site) => {
    site.settings.active = active;
    siteAPI
      .updateSite(site.key(), site.settings)
      .then(resp => {})
      .catch(err => {});
    setSites([...sites]);
  };

  const desktopView = () => {
    return (
      <MaterialTable
        title={"Jobsites"}
        columns={[
          {
            title: "Site",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            },
            render: (row: SiteWithData) => <Typography>{row.site.siteName()}</Typography>
          },
          {
            title: "Heaters",
            sorting: false,
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            },
            render: (row: SiteWithData) => (
              <Grid container spacing={1}>
                {row.heaterData.map((hd: pond.ObjectHeaterData) => (
                  <Grid item key={hd.heater?.key}>
                    {hd.heater?.name}
                  </Grid>
                ))}
              </Grid>
            )
          },
          {
            title: "Users",
            sorting: false,
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            },
            render: (row: SiteWithData) => (
              <Grid container spacing={1}>
                {row.users.map((user: User) => (
                  <Grid item key={user.id()}>
                    <Tooltip title={user.name()}>
                      {user.settings.avatar ? (
                        <Avatar alt={user.settings.name} src={user.settings.avatar} />
                      ) : (
                        <Avatar alt={user.settings.name}>
                          <Group />
                        </Avatar>
                      )}
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            )
          },
          {
            title: "Active",
            headerStyle: {
              fontWeight: 650,
              fontSize: 20
            },
            render: (row: SiteWithData) => (
              <React.Fragment>
                <Switch
                  defaultChecked={row.site.settings.active}
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  onChange={e => {
                    updateActive(e.target.checked, row.site);
                  }}
                />
              </React.Fragment>
            ),
            customSort: (a, b) => {
              return a.site.settings.active === b.site.settings.active
                ? 0
                : a.site.settings.active
                ? -1
                : 1;
            }
          }
        ]}
        data={sites}
        icons={getTableIcons()}
        onRowClick={(_, jobsite) => {
          jobsite && goToSite(jobsite);
        }}
        options={{
          pageSize: 10
        }}
      />
    );
  };

  function TabPanelMine(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        aria-labelledby={`simple-tab-${index}`}
        {...other}>
        {value === index && <React.Fragment>{children}</React.Fragment>}
      </div>
    );
  }

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setCurrentTab(newValue);
  };

  const mobileView = () => {
    return (
      <React.Fragment>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleChange}
          centered
          style={{
            marginLeft: "auto"
          }}>
          <Tab label="Active" />
          <Tab label="Inactive" />
        </Tabs>
        <TabPanelMine value={currentTab} index={0}>
          {sites.map(site => {
            let siteCards: JSX.Element[] = [];
            if (site.site.settings.active) {
              siteCards.push(
                <Card
                  key={site.site.key()}
                  style={{ margin: 10, padding: 5 }}
                  onClick={() => {
                    goToSite(site);
                  }}>
                  <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <JobsiteIcon size={50} />
                    </Grid>
                    <Grid item xs={7}>
                      {/* information (site name, number of devices, teams in site) */}
                      {siteInfoDisplay(site)}
                    </Grid>
                    <Grid item xs={3}>
                      <Switch
                        defaultChecked={site.site.settings.active}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        onChange={e => {
                          updateActive(e.target.checked, site.site);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              );
            }
            return siteCards;
          })}
        </TabPanelMine>
        <TabPanelMine value={currentTab} index={1}>
          {sites.map(site => {
            let siteCards: JSX.Element[] = [];
            if (!site.site.settings.active) {
              siteCards.push(
                <Card
                  key={site.site.key()}
                  style={{ margin: 10, padding: 5 }}
                  onClick={() => {
                    goToSite(site);
                  }}>
                  <Grid container alignContent="center" alignItems="center">
                    <Grid item xs={2} style={{ textAlign: "center" }}>
                      <JobsiteIcon size={50} />
                    </Grid>
                    <Grid item xs={7}>
                      {/* information (site name, number of devices, teams in site) */}
                      {siteInfoDisplay(site)}
                    </Grid>
                    <Grid item xs={3}>
                      <Switch
                        defaultChecked={site.site.settings.active}
                        onClick={e => {
                          e.stopPropagation();
                        }}
                        onChange={e => {
                          updateActive(e.target.checked, site.site);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              );
            }
            return siteCards;
          })}
        </TabPanelMine>
      </React.Fragment>
    );
  };

  return <PageContainer>{isMobile ? mobileView() : desktopView()}</PageContainer>;
}
