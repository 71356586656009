import Co2DarkIcon from "assets/components/co2Dark.png";
import Co2LightIcon from "assets/components/co2Light.png";
import { ImgIcon } from "common/ImgIcon";
import { useThemeType } from "hooks";
import React from "react";

interface Props {
  type?: "light" | "dark";
  size?: number;
}

export default function Co2Icon(props: Props) {
  const themeType = useThemeType();
  const { type, size } = props;

  const src = () => {
    if (type) {
      return type === "light" ? Co2LightIcon : Co2DarkIcon;
    }

    return themeType === "light" ? Co2DarkIcon : Co2LightIcon;
  };

  if (size) {
    return <img width={size} height={size} alt="co2" src={src()} />;
  }

  return <ImgIcon alt="co2" src={src()} />;
}
