import { DeviceProductDescriber } from "products/DeviceProduct";
import { pond } from "protobuf-ts/pond";
import DeviceDarkIcon from "assets/products/Ag/device 1.png";
import DeviceLightIcon from "assets/products/Ag/device 2.png";
import { MiVentV1Availability, MiVentV2Availability } from "./MiVentAvailability";

export const MiVent: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_MIVENT,
  label: "MiVent V1",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? DeviceDarkIcon : DeviceLightIcon;
  },
  view: "controller",
  tabs: ["components"],
  availability: MiVentV1Availability
};

/*---V2 Stuff starts here---*/

export const MiVentV2: DeviceProductDescriber = {
  product: pond.DeviceProduct.DEVICE_PRODUCT_MIVENT_V2,
  label: "MiVent V2",
  icon: (_, theme?: "light" | "dark") => {
    return theme === "light" ? DeviceDarkIcon : DeviceLightIcon;
  },
  view: "controller",
  tabs: ["components"],
  availability: MiVentV2Availability
};
