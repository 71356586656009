import React from "react";
import {
  DeviceUnknown,
  SignalCellular4Bar,
  SignalWifi4Bar,
  SignalCellularOff,
  SignalWifiOff
} from "@material-ui/icons";
import { pond } from "protobuf-ts/pond";

export interface DevicePlatformDescriber {
  platform: pond.DevicePlatform;
  label: string;
  description: string;
  icon: any;
  offlineIcon: any;
  platformName: string;
}

const Default: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_INVALID,
  label: "Unknown",
  description: "Unknown communication platform",
  icon: <DeviceUnknown style={{ color: "#fff" }} />,
  offlineIcon: <DeviceUnknown style={{ color: "#fff" }} />,
  platformName: "unknown"
};

const Photon: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_PHOTON,
  label: "Wi-Fi",
  description: "Communicates via Wi-Fi",
  icon: <SignalWifi4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalWifiOff style={{ color: "#fff" }} />,
  platformName: "photon"
};

const Electron: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_ELECTRON,
  label: "Cellular",
  description: "Communicates via cellular",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "electron"
};

const V2_Cellular: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR,
  label: "V2 Cellular",
  description: "Communicates via cellular",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "v2cellular"
};

const V2_Wifi_S3: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI_S3,
  label: "V2 Wifi S3",
  description: "Communicates via cellular",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "v2wifis3"
};

const V2_Cell_Black: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_BLACK,
  label: "V2 Cellular Black",
  description: "Communicates via cellular",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "v2cellblack"
};

const V2_Cell_Green: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_GREEN,
  label: "V2 Cellular Green",
  description: "Communicates via cellular",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "v2cellgreen"
};

const V2_Cell_Blue: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_BLUE,
  label: "V2 Cellular Blue",
  description: "Communicates via cellular",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "v2cellblue"
};

const V2_Wifi_Blue: DevicePlatformDescriber = {
  platform: pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI_BLUE,
  label: "V2 Wifi Blue",
  description: "Communicates via wifi",
  icon: <SignalCellular4Bar style={{ color: "#fff" }} />,
  offlineIcon: <SignalCellularOff style={{ color: "#fff" }} />,
  platformName: "v2wifiblue"
};

const map = new Map<pond.DevicePlatform, DevicePlatformDescriber>([
  [pond.DevicePlatform.DEVICE_PLATFORM_INVALID, Default],
  [pond.DevicePlatform.DEVICE_PLATFORM_PHOTON, Photon],
  [pond.DevicePlatform.DEVICE_PLATFORM_ELECTRON, Electron],
  [pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR, V2_Cellular],
  [pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI_S3, V2_Wifi_S3],
  [pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_BLACK, V2_Cell_Black],
  [pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_GREEN, V2_Cell_Green],
  [pond.DevicePlatform.DEVICE_PLATFORM_V2_CELLULAR_BLUE, V2_Cell_Blue],
  [pond.DevicePlatform.DEVICE_PLATFORM_V2_WIFI_BLUE, V2_Wifi_Blue]
]);

export function getDevicePlatformDescriber(platform: pond.DevicePlatform): DevicePlatformDescriber {
  const describer = map.get(platform);
  return describer ? describer : Default;
}

export function getDevicePlatformLabel(platform: pond.DevicePlatform): string {
  return getDevicePlatformDescriber(platform).label;
}

export function getDevicePlatformDescription(platform: pond.DevicePlatform): string {
  return getDevicePlatformDescriber(platform).description;
}

export function getDevicePlatformIcon(platform: pond.DevicePlatform): any {
  return getDevicePlatformDescriber(platform).icon;
}

export function getDevicePlatformName(platform: pond.DevicePlatform): string {
  return getDevicePlatformDescriber(platform).platformName;
}

export function GetAllDevicePlatformDescribers(): DevicePlatformDescriber[] {
  return [...map.values()].filter(p => p.platform !== pond.DevicePlatform.DEVICE_PLATFORM_INVALID);
}
