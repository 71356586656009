import { useHTTP } from "hooks";
import React, { createContext, PropsWithChildren, useContext } from "react";
import { pond } from "protobuf-ts/pond";
import { pondURL } from "./pond";
import { AxiosResponse } from "axios";
import { useGlobalState } from "providers";

export interface ITerminalAPIContext {
  addTerminal: (
    name: string,
    terminal: pond.TerminalSettings
  ) => Promise<AxiosResponse<pond.AddTerminalResponse>>;
  listTerminals: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string
  ) => Promise<AxiosResponse<pond.ListTerminalsResponse>>;
  listTerminalsAndGates: (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string
  ) => Promise<AxiosResponse<pond.ListTerminalsAndGatesResponse>>;
  updateTerminal: (
    key: string,
    name: string,
    settings: pond.TerminalSettings
  ) => Promise<AxiosResponse<pond.UpdateTerminalResponse>>;
  removeTerminal: (key: string) => Promise<AxiosResponse<pond.RemoveTerminalResponse>>;
}

export const TerminalAPIContext = createContext<ITerminalAPIContext>({} as ITerminalAPIContext);

interface Props {}

export default function TerminalProvider(props: PropsWithChildren<Props>) {
  const { children } = props;
  const { get, del, post, put } = useHTTP();
  const [{ as }] = useGlobalState();

  const addTerminal = (name: string, terminal: pond.TerminalSettings) => {
    if (as)
      return post<pond.AddTerminalResponse>(
        pondURL("/terminals?name=" + name + "&as=" + as),
        terminal
      );
    return post<pond.AddTerminalResponse>(pondURL("/terminals?name=" + name), terminal);
  };

  const listTerminals = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string
  ) => {
    if (as)
      return get<pond.ListTerminalsResponse>(
        pondURL(
          "/terminals?as=" +
            as +
            "&limit=" +
            limit +
            "&offset=" +
            offset +
            ("&order=" + (order ? order : "asc")) +
            ("&by=" + (orderBy ? orderBy : "key")) +
            (search ? "&search=" + search : "")
        )
      );
    return get<pond.ListTerminalsResponse>(
      pondURL(
        "/terminals?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (search ? "&search=" + search : "")
      )
    );
  };

  const listTerminalsAndGates = (
    limit: number,
    offset: number,
    order?: "asc" | "desc",
    orderBy?: string,
    search?: string
  ) => {
    if (as)
      return get<pond.ListTerminalsAndGatesResponse>(
        pondURL(
          "/terminalsAndGates?as=" +
            as +
            "&limit=" +
            limit +
            "&offset=" +
            offset +
            ("&order=" + (order ? order : "asc")) +
            ("&by=" + (orderBy ? orderBy : "key")) +
            (search ? "&search=" + search : "")
        )
      );
    return get<pond.ListTerminalsAndGatesResponse>(
      pondURL(
        "/terminalsAndGates?limit=" +
          limit +
          "&offset=" +
          offset +
          ("&order=" + (order ? order : "asc")) +
          ("&by=" + (orderBy ? orderBy : "key")) +
          (search ? "&search=" + search : "")
      )
    );
  };

  const updateTerminal = (key: string, name: string, settings: pond.TerminalSettings) => {
    if (as) {
      return put<pond.UpdateTerminalResponse>(
        pondURL("/terminals/" + key + "?as=" + as + "&name=" + name),
        settings
      );
    }
    return put<pond.UpdateTerminalResponse>(
      pondURL("/terminals/" + key + "?name=" + name),
      settings
    );
  };

  const removeTerminal = (key: string) => {
    if (as) {
      return del<pond.RemoveTerminalResponse>(pondURL("/terminals/" + key + "?as=" + as));
    }
    return del<pond.RemoveTerminalResponse>(pondURL("/terminals/" + key));
  };

  return (
    <TerminalAPIContext.Provider
      value={{
        addTerminal,
        listTerminals,
        updateTerminal,
        removeTerminal,
        listTerminalsAndGates
      }}>
      {children}
    </TerminalAPIContext.Provider>
  );
}

export const useTerminalAPI = () => useContext(TerminalAPIContext);
