import { ConfigurablePin } from "pbHelpers/AddressTypes";
import { DeviceAvailabilityMap, DevicePositions } from "pbHelpers/DeviceAvailability";
import { quack } from "protobuf-ts/pond";

const MiVentV1Pins: ConfigurablePin[] = [
  { address: 4, label: "1" },
  { address: 8, label: "2" },
  { address: 16, label: "3" },
  { address: 512, label: "C1" },
  { address: 1024, label: "C2" }
];

export const MiVentV1Availability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, MiVentV1Pins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]],
      [quack.ComponentType.COMPONENT_TYPE_AIRFLOW, [0x2a]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);

/*---V2 Stuff starts here---*/

const MiVentV2Pins: ConfigurablePin[] = [
  { address: 1, label: "1" },
  { address: 2, label: "2" },
  { address: 4, label: "3" },
  { address: 256, label: "L1" },
  { address: 512, label: "L2" }
];

export const MiVentV2Availability: DeviceAvailabilityMap = new Map<
  quack.AddressType,
  DevicePositions
>([
  [quack.AddressType.ADDRESS_TYPE_INVALID, []],
  [quack.AddressType.ADDRESS_TYPE_CONFIGURABLE_PIN_ARRAY, MiVentV2Pins],
  [
    quack.AddressType.ADDRESS_TYPE_I2C,
    new Map<quack.ComponentType, number[]>([
      [quack.ComponentType.COMPONENT_TYPE_PRESSURE, [0x18]],
      [quack.ComponentType.COMPONENT_TYPE_DHT, [0x40]],
      [quack.ComponentType.COMPONENT_TYPE_SEN5X, [0x69]],
      [quack.ComponentType.COMPONENT_TYPE_AIRFLOW, [0x2a]]
    ])
  ],
  [quack.AddressType.ADDRESS_TYPE_POWER, [0]],
  [quack.AddressType.ADDRESS_TYPE_GPS, [0]],
  [quack.AddressType.ADDRESS_TYPE_MODEM, [0]]
]);
